import svg1 from '../../patterns/patter-1.svg';
import svg2 from '../../patterns/patter-2.svg';
import svg3 from '../../patterns/patter-3.svg';
import svg4 from '../../patterns/patter-4.svg';
import svg5 from '../../patterns/patter-5.svg';
import svg6 from '../../patterns/patter-6.svg';
import svg7 from '../../patterns/patter-7.svg';
import svg8 from '../../patterns/patter-8.svg';
import svg9 from '../../patterns/patter-9.svg';

const patterns = {1: svg1, 2: svg2, 3: svg3, 4: svg4, 5: svg5, 6: svg6, 7: svg7, 8: svg8, 9: svg9,}

const prefixes = ["div.page-header__bg_patter-type_", "div.card__pic-bg-img_"];

const importPatterns = () => {
    for (const [pattern_type, svg] of Object.entries(patterns)) {
        prefixes.forEach(prefix => {
            document.querySelectorAll(prefix + pattern_type).forEach(block => {
                block.childElementCount === 0 && block.insertAdjacentHTML('afterbegin', svg);
            })
        });
    }
};

importPatterns();

export default importPatterns
